.link_button {
    color: white;
    margin: 10px auto;
    cursor: pointer;
    background-image: url(./buttons/V1-Selected-button.png);
    border-radius: 30px;
    height: 50px;
    box-shadow: inset -2px -1px 8px 4px #cdd6d8;
    display: flex;
    align-items: center;
    justify-content: center;
}

.link_button img {
    width: 70%;
}