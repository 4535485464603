body {
    background-color: #f3f3f1;
}

.links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5rem .8rem;
}

.links a {
    text-decoration: none;
    color: #212529;
}

.frm-error,.frm-error .css-1s2u09g-control {
    border-color: #dc3545 !important;
}

.preview {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: inherit;
}

.inner {
    transition: all .5s cubic-bezier(.08,.59,.29,.99) .2s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translate(-50%);
    opacity: 1;
}

.preview-wrap {
    width: 352px;
    height: 724px;
    padding: 16px;
    display: block;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: top left;
    transform: scale(0.85) translateX(-50%) translateY(-50%) translate3d(0px, 0px, 0px);
}

.preview-wrap:after {
    content: "";
    background-image: url('../public/assets/preview-device.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    inset: 0;
    pointer-events: none;
}

.iframe-wrapper {
    height: 100%;
}

iframe {
    border: 0px;
    height: 100%;
    width: 100%;
}